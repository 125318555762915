var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.enableWeeklyReport)?_c('base-section-card',[_c('v-expand-transition',[(_vm.errors.length !== 0)?_c('base-alert',{attrs:{"type":"error","light":""}},[_vm._v(" 設定された"+_vm._s(_vm.errorSummary)+"に修正が必要な箇所があります"),_c('br'),_vm._v(" 週報担当者編集をクリックして、修正箇所を確認してください。 ")]):_vm._e()],1),_c('base-container',{attrs:{"horizontal-space-between":"","vertical-center":""}},[_c('base-modal',{attrs:{"title":("週報担当者" + (_vm.frozen ? '履歴' : '編集')),"activator":"","activator-text":("週報担当者" + (_vm.frozen ? '履歴' : '編集')),"activator-icon":("fas fa-" + (_vm.frozen ? 'eye' : 'pen')),"only-confirm":"","confirm-text":"完了"},scopedSlots:_vm._u([{key:"titleHint",fn:function(){return [_vm._v(" その週の日曜日時点で指定されている人が週報担当者となります。 ")]},proxy:true}],null,false,3299765583)},[(_vm.showEmptyMessage)?_c('base-alert',{attrs:{"type":"warning","light":""}},[_vm._v(" 週報担当者はまだ設定されていません ")]):_vm._l((_vm.value),function(item,index){return _c('div',{key:index},[_c('base-row',[_c('base-col',{attrs:{"cols":"3"}},[_c('date-picker-datetime',{attrs:{"value":item.begin,"outlined":"","flat":"","dense":"","hide-details":"","suffix":"～","frozen":_vm.frozen,"empty-text":"(指定なし)","error":_vm.errors
                    .filter(function (v) { return v.row === index; }).some(function (v) { return v.type === 'begin'; }),"allowed-dates":_vm.allowedDates},on:{"input":function (v) { return _vm.changeItem({
                  user_manager_id: item.user_manager_id,
                  manager_no: item.manager_no,
                  manager_firstname: item.manager_firstname,
                  manager_lastname: item.manager_lastname,
                  begin: v,
                  end: item.end,
                  enabled: item.enabled,
                }, index); }}})],1),_c('base-col',{attrs:{"cols":"4"}},[_c('base-select',{attrs:{"items":_vm.managerList(item),"place-holder":"(担当者なし)","allow-no-select":"","no-select-label":"(担当者なし)","no-select-value":-1,"frozen":_vm.frozen,"disabled":!_vm.existManager(item.manager_no) || !item.enabled,"error":_vm.errors
                    .filter(function (v) { return v.row === index; }).some(function (v) { return v.type === 'manager'; })},on:{"input":function (v) { return _vm.changeItem({
                    user_manager_id: item.user_manager_id,
                    manager_no: v,
                    manager_firstname: item.manager_firstname,
                    manager_lastname: item.manager_lastname,
                    begin: item.begin,
                    end: item.end,
                    enabled: item.enabled,
                }, index); }},model:{value:(item.manager_no),callback:function ($$v) {_vm.$set(item, "manager_no", $$v)},expression:"item.manager_no"}})],1),_c('base-col',{attrs:{"cols":"3"}},[_c('base-switch',{attrs:{"true-label":"週報管理する","false-label":"週報管理しない","frozen":_vm.frozen || !_vm.existManager(item.manager_no)},on:{"input":function (v) { return _vm.changeTarget(v, index, item); }},model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}})],1),_c('base-col',{attrs:{"cols":"2"}},[_c('base-container',{attrs:{"vertical-center":""}},[(!_vm.frozen)?_c('base-button',{attrs:{"icon":"fas fa-trash","label":"削除","text":"","color":"error","required-acl":[{ category: 'user', action: 'mod' }]},on:{"click":function($event){return _vm.deleteItem(index)}},scopedSlots:_vm._u([{key:"confirmMessage",fn:function(){return [_vm._v(" この行を削除しますか？ ")]},proxy:true}],null,true)}):_vm._e()],1)],1)],1),_c('error-message-box',{attrs:{"errors":_vm.errors.filter(function (v) { return v.row === index; }).map(function (v) { return v.message; }),"dense":"","text":""}})],1)}),_c('base-container',[(!_vm.frozen)?_c('base-button',{attrs:{"label":"行を追加する","text":"","icon":"fas fa-plus","required-acl":[{ category: 'user', action: 'mod' }]},on:{"click":_vm.addColumn}}):_vm._e()],1)],2),_c('div',[(_vm.loading)?_c('base-typography',{attrs:{"single":""}},[_vm._v(" 読み込み中... ")]):[(_vm.currentDisabled)?_c('base-typography',{attrs:{"value":"現在は週報管理されていません","single":""}}):(!_vm.currentManager)?_c('base-typography',{attrs:{"value":"担当者が設定されていません","single":""}}):[_c('base-typography',{attrs:{"value":"現在は以下が設定されています"}}),_c('base-container',{attrs:{"horizontal-center":"","vertical-center":""}},[_c('base-typography',{attrs:{"single":"","value":_vm.currentManager}})],1)]]],2)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }