var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-section-card',[_c('v-expand-transition',[(!_vm.frozen && _vm.errors.length !== 0)?_c('base-alert',{attrs:{"type":"error","light":""}},[_vm._v(" 設定された"+_vm._s(_vm.errorSummary)+"に修正が必要な箇所があります"),_c('br'),_vm._v(" 所属・部門編集をクリックして、修正箇所を確認してください。 ")]):_vm._e()],1),_c('base-container',{attrs:{"horizontal-space-between":"","vertical-center":""}},[_c('base-modal',{attrs:{"title":("所属・部門" + (_vm.frozen ? '履歴' : '編集')),"activator":"","activator-text":("所属・部門" + (_vm.frozen ? '履歴' : '編集')),"activator-icon":("fas fa-" + (_vm.frozen ? 'eye' : 'pen')),"only-confirm":"","confirm-text":"完了"}},[(_vm.showEmptyMessage)?_c('base-alert',{attrs:{"type":"warning","light":""}},[_vm._v(" 所属・部門情報はまだ登録されていません ")]):_vm._l((_vm.value),function(item,index){return _c('div',{key:index},[_c('base-row',[_c('base-col',{attrs:{"cols":"6"}},[_c('base-container',{attrs:{"vertical-center":"","horizontal-center":""}},[_c('date-range-picker-datetime',{attrs:{"start":item.begin,"end":item.end,"outlined":"","flat":"","clearable":"","empty-text-begin":"(入社日)","empty-text-end":"(期限なし)","frozen":_vm.frozen,"error":_vm.errors
                    .filter(function (v) { return v.row === index; }).some(function (v) { return v.type === 'begin_end'; })},on:{"update:start":function (v) { return _vm.changeItem({
                    begin: v,
                    end: item.end,
                    department_id: item.department_id,
                    user_department_link_id: item.user_department_link_id,
                  }, index); },"update:end":function (v) { return _vm.changeItem({
                    begin: item.begin,
                    end: v,
                    department_id: item.department_id,
                    user_department_link_id: item.user_department_link_id
                  }, index); }}})],1)],1),_c('base-col',{attrs:{"cols":"4"}},[_c('base-container',{attrs:{"vertical-center":"","horizontal-center":""}},[_c('department-select-box',{attrs:{"use-please-select":"","frozen":_vm.frozen,"error":_vm.errors
                    .filter(function (v) { return v.row === index; }).some(function (v) { return v.type === 'department'; })},on:{"input":function (v) { return _vm.changeItem({
                    begin: item.begin,
                    end: item.end,
                    department_id: v,
                    user_department_link_id: item.user_department_link_id,
                  }, index); }},model:{value:(item.department_id),callback:function ($$v) {_vm.$set(item, "department_id", $$v)},expression:"item.department_id"}})],1)],1),_c('base-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('base-container',{attrs:{"horizontal-end":"","vertical-center":""}},[(!_vm.frozen)?_c('base-button',{attrs:{"icon":"fas fa-trash","label":"削除","text":"","color":"error","required-acl":[{ category: 'user', action: 'mod' }]},on:{"click":function($event){return _vm.deleteItem(index)}},scopedSlots:_vm._u([{key:"confirmMessage",fn:function(){return [_vm._v(" この行を削除しますか？ ")]},proxy:true}],null,true)}):_vm._e()],1)],1)],1),_c('error-message-box',{attrs:{"errors":_vm.errors.filter(function (v) { return v.row === index; }).map(function (v) { return v.message; }),"dense":"","text":""}})],1)}),_c('base-container',[(!_vm.frozen)?_c('base-button',{attrs:{"label":"行を追加する","text":"","small":"","icon":"fas fa-plus","required-acl":[{ category: 'user', action: 'mod' }]},on:{"click":_vm.addColumn}}):_vm._e()],1)],2),_c('div',[(_vm.loading)?_c('base-typography',{attrs:{"single":""}},[_vm._v(" 読み込み中... ")]):[(_vm.currentEnabledDepartmentList.length === 0)?_c('base-typography',{attrs:{"value":"現在は所属・部門が設定されていません","single":""}}):[_c('base-typography',{attrs:{"value":"現在は以下が設定されています"}}),_vm._l((_vm.currentEnabledDepartmentList),function(department,index){return _c('base-container',{key:index,attrs:{"horizontal-space-between":"","vertical-center":""}},[_c('base-typography',{attrs:{"value":department.name,"single":""}}),_c('base-typography',{attrs:{"single":"","caption":""}},[_vm._v(" ( ~ "),_c('base-text',{attrs:{"value":department.end.dateStrJp({ undefinedText: '期限なし' })}}),_vm._v(" ) ")],1)],1)})]]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }